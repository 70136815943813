export default function IconMarker() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.75 -0.75 16 16"
      id="Location-Pin--Streamline-Micro"
      height="100%"
      width="100%"
    >
      <desc>{'Location Pin Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.250000000000001 13.775000000000002s5.800000000000001 -3.6250000000000004 5.800000000000001 -7.250000000000001a5.800000000000001 5.800000000000001 0 1 0 -11.600000000000001 0c0 3.6250000000000004 5.800000000000001 7.250000000000001 5.800000000000001 7.250000000000001Z"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.250000000000001 6.525a0.7250000000000001 0.7250000000000001 0 0 1 0 -1.4500000000000002"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.250000000000001 6.525a0.7250000000000001 0.7250000000000001 0 0 0 0 -1.4500000000000002"
        strokeWidth={1.5}
      />
    </svg>
  );
}
